
import { Instructors } from "@/api";
import { Instructor, InstructorsWithPagination } from "@/api/types";
import TitleHeader from "@/components/common/TitleHeader.vue";
import { Component, Vue, Watch } from "vue-property-decorator";
@Component({
  components: { TitleHeader }
})
export default class InstructorsPage extends Vue {
  filters = {
    name: "",
    email: "",
    active: "all"
  };
  options = {};
  page = 1;
  limit = 10;
  instructors: Array<Instructor> = [];
  instructorsWithPagination: InstructorsWithPagination = {
    meta: {
      perPage: this.limit
    }
  };
  headers = [
    {
      text: "Name",
      value: "name"
    },
    {
      text: "Email",
      value: "email"
    },
    {
      text: "Phone",
      value: "phone"
    },
    {
      text: "Actions",
      value: "controls",
      sortable: false,
      width: "250px"
    }
  ];

  @Watch("options", { deep: true })
  async onOptionsChange(val: any) {
    this.limit = val.itemsPerPage;
    if (this.limit === -1) this.limit = 9999;
    await this.loadInstructorsData(val.page);
  }
  @Watch("filters", { deep: true })
  async onFiltersChange(val: any) {
    await this.loadInstructorsData(this.page, val);
  }
  async loadInstructorsData(
    page = 1,
    filters: { name: string; email: string; active: number | string } = {
      name: "",
      email: "",
      active: "all"
    }
  ) {
    try {
      const instructorsWithPagination = await Instructors.list(
        page,
        this.limit,
        filters
      );
      this.instructors = instructorsWithPagination.data
        ? instructorsWithPagination.data
        : [];
      this.instructorsWithPagination = instructorsWithPagination;
    } catch (e) {
      const err = e as any;
      await this.$error(err.errors);
    }
  }
  async onView(id: number) {
    await this.$router.push({
      name: "ViewInstructor",
      params: { id: id.toString() }
    });
  }
  async onEdit(id: number) {
    await this.$router.push({
      name: "EditInstructor",
      params: { id: id.toString() }
    });
  }
  async onCreate() {
    await this.$router.push({ name: "CreateInstructor" });
  }
}
